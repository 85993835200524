import React from "react";
import "./QAPage.css";

const QAPageHeader = () => {
  return (
    <div className="QAPageHeader">
      <img
        src="https://media.istockphoto.com/id/1337475990/photo/q-and-a-question-and-answer-shot-form-on-wooden-block.jpg?s=612x612&w=0&k=20&c=LrALcokTfC-1-1SD3WM1rgVYFIFu4TL7u47xlEeh2VQ="
        alt=""
      />
    </div>
  );
};

export default QAPageHeader;
